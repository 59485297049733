import React from 'react';
import FadeIn from 'react-fade-in';
import { BACKEND_DOWN_ERR_MESSAGE } from '../../constants/message';
import { EmptyFiller } from '../empty-filler';
import { LoadingSpinner } from '../loading-spinner';
import './ChannelMessages.scss';
import EmptyChatIndicator from './EmptyChatIndicator';
import Message from './Message';

class ChatBox extends React.Component {
  render() {
    const { messages, isLoading, fetchSucceeded, room } = this.props;
    return (
      <div id="message-list-container" className="chat" style={{ overflow: 'auto', outline: 'none' }} tabIndex="5001">
        {messages && messages.length > 0 ? (
          <div className="col-inside-lg decor-default">
            <div className="chat-body">
              {isLoading ? (
                <LoadingSpinner />
              ) : fetchSucceeded ? (
                messages.map((eachMessage, i) => (
                  <FadeIn key={`${eachMessage.message_id}`} delay="200">
                    <Message room={room} {...eachMessage} />
                  </FadeIn>
                ))
              ) : (
                <EmptyFiller customMessage={BACKEND_DOWN_ERR_MESSAGE} />
              )}
            </div>
          </div>
        ) : (
          <EmptyChatIndicator placeholderText="Send the first message! Type a message in the box below." />
        )}
      </div>
    );
  }
}

export default ChatBox;
